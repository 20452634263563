// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RadioButtonScss from "./RadioButton.scss";

var css = RadioButtonScss;

function RadioButton(props) {
  var onClick = props.onClick;
  var __className = props.className;
  var __visuallyDisabled = props.visuallyDisabled;
  var __disabled = props.disabled;
  var id = props.id;
  var disabled = __disabled !== undefined ? __disabled : false;
  var visuallyDisabled = __visuallyDisabled !== undefined ? __visuallyDisabled : false;
  var className = __className !== undefined ? __className : "";
  var radioButtonRef = React.useRef(null);
  var handleClick = function ($$event) {
    var radioButtonRef$1 = radioButtonRef.current;
    if (!(radioButtonRef$1 == null)) {
      if (onClick !== undefined) {
        onClick($$event);
      }
      radioButtonRef$1.blur();
      return ;
    }
    
  };
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("input", {
                      ref: Caml_option.some(radioButtonRef),
                      className: Cx.cx([
                            css.radioButton,
                            visuallyDisabled ? css.visuallyDisabled : ""
                          ]),
                      id: id,
                      tabIndex: props.tabIndex,
                      checked: props.checked,
                      disabled: disabled || visuallyDisabled,
                      type: "radio",
                      onChange: props.onChange,
                      onClick: handleClick
                    }),
                JsxRuntime.jsx("label", {
                      className: css.label,
                      htmlFor: id
                    })
              ],
              className: Cx.cx([
                    css.wrapper,
                    className
                  ])
            });
}

var make = RadioButton;

export {
  css ,
  make ,
}
/* css Not a pure module */
