// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateCloudProjectCloudTypeSelection from "./CreateCloudProjectCloudTypeSelection.res.js";

function CreateCloudProjectCloudTypeSelection__JsBridge$default(props) {
  return JsxRuntime.jsx(CreateCloudProjectCloudTypeSelection.make, {
              cloudTypes: Belt_Array.map(props.cloudTypes, Project.Cloud.fromString)
            });
}

var $$default = CreateCloudProjectCloudTypeSelection__JsBridge$default;

export {
  $$default as default,
}
/* Project Not a pure module */
