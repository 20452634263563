// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../../../../utils/Url.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Page from "../../../../routes/common/Routes_Page.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardNavigation from "../_components/ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as ProjectCloudTypeRadioTile from "../_components/ProjectCloudTypeRadioTile/ProjectCloudTypeRadioTile.res.js";
import * as CreateCloudProjectCloudTypeSelectionScss from "./CreateCloudProjectCloudTypeSelection.scss";

var css = CreateCloudProjectCloudTypeSelectionScss;

function header(cloud) {
  if (cloud === "IaasServer") {
    return "Cloud IaaS - Cloud Servers";
  } else {
    return "Cloud IaaS - Cloud Storage";
  }
}

function description(cloud) {
  if (cloud === "IaasServer") {
    return "Start your project for cloud Infrastructure as a Service (IaaS). Enter your cloud server requirements, select cloud providers and start receiving pricing.";
  } else {
    return "Start your project for cloud Infrastructure as a Service (IaaS). Enter your cloud storage requirements, select cloud providers and start receiving pricing.";
  }
}

var CloudType = {
  header: header,
  description: description
};

function CreateCloudProjectCloudTypeSelection(props) {
  var initialState = React.useMemo((function () {
          return {
                  selectedCloudType: undefined
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          return {
                  TAG: "Update",
                  _0: {
                    selectedCloudType: action._0
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var navigationPreviousControl_1 = function (param) {
    Url.visit(Routes_Project.index);
  };
  var navigationPreviousControl = [
    "Cancel",
    navigationPreviousControl_1,
    "Enabled"
  ];
  var navigationNextControl = React.useMemo((function () {
          var match = state.selectedCloudType;
          return [
                  "Next Step",
                  (function () {
                      var cloudType = state.selectedCloudType;
                      if (cloudType !== undefined) {
                        return Url.visit(Routes_Project.Cloud.Configuration.$$new(cloudType));
                      }
                      
                    }),
                  match !== undefined ? "Enabled" : "Disabled"
                ];
        }), [state.selectedCloudType]);
  var cloudType = state.selectedCloudType;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("h1", {
                                    children: "Cloud RFP for IaaS"
                                  }),
                              className: css.tooltipAndHeadingWrapper
                            }),
                        Belt_Array.map(props.cloudTypes, (function (cloudType) {
                                var selectedCloud = state.selectedCloudType;
                                return JsxRuntime.jsx(ProjectCloudTypeRadioTile.make, {
                                            cloudType: cloudType,
                                            checked: selectedCloud !== undefined ? cloudType === selectedCloud : false,
                                            onClick: (function (param) {
                                                dispatch({
                                                      TAG: "SelectCloudType",
                                                      _0: cloudType
                                                    });
                                              })
                                          }, Project.Category.toString({
                                                TAG: "Cloud",
                                                _0: cloudType
                                              }));
                              })),
                        cloudType !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx("h3", {
                                        children: header(cloudType),
                                        className: css.cloudHeading
                                      }),
                                  JsxRuntime.jsx("p", {
                                        children: description(cloudType),
                                        className: css.cloudDescription
                                      })
                                ]
                              }) : JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsxs("p", {
                                        children: [
                                          "Searching for a quick and easy way to create a cloud RFP online? Datacenters.com has created a digital RFP template for popular cloud services including ",
                                          JsxRuntime.jsx("a", {
                                                children: "cloud servers",
                                                href: Routes_Project.IaasServer.$$new
                                              }),
                                          " and ",
                                          JsxRuntime.jsx("a", {
                                                children: "cloud storage",
                                                href: Routes_Project.IaasStorage.$$new
                                              }),
                                          ". Want to build your cloud requirements and distribute them to top cloud service providers online? We offer access to major cloud providers globally."
                                        ],
                                        className: css.cloudDescription
                                      }),
                                  JsxRuntime.jsx("p", {
                                        children: "How exactly does it work? We've created pre-defined cloud RFP questions that cloud service providers ask in order to provide quotes and orders. This includes questions about your compute and storage requirements, software licenses, recovery time and point objectives, and communications. When you select a cloud service and answer the questions, our matching algorithm will automatically select the best cloud service providers based on your specifications and requirements.",
                                        className: css.cloudDescription
                                      }),
                                  JsxRuntime.jsx("p", {
                                        children: "Once matched with providers, you can select all or deselect certain providers to include only the providers that you are most interested in receiving RFP responses and quotes from. The last part of the three-step process is to sign-up on Datacenters.com, include contact information and any project details or documents.",
                                        className: css.cloudDescription
                                      }),
                                  JsxRuntime.jsx("p", {
                                        children: "Your completed cloud RFP including all information and details will be sent directly to the cloud providers you selected. They will in turn receive you RFP and respond directly within the Datacenters.com RFP Platform. You will receive email alerts when providers respond to your cloud RFP. You can view your cloud RFP responses and quotes at any time.",
                                        className: css.cloudDescription
                                      }),
                                  JsxRuntime.jsxs("p", {
                                        children: [
                                          "Need help building your cloud service requirements? ",
                                          JsxRuntime.jsx("a", {
                                                children: "Datacenters.com",
                                                href: "/"
                                              }),
                                          " offers free cloud consulting services. ",
                                          JsxRuntime.jsx("a", {
                                                children: "Contact us",
                                                href: Routes_Page.contact
                                              }),
                                          " now to speak with a Datacenters.com Cloud Advisor."
                                        ],
                                        className: css.cloudDescription
                                      })
                                ],
                                className: css.cloudTextContainer
                              })
                      ],
                      className: css.contentWrapper
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation.make, {
                      stepLabel: "Select Category",
                      controls: {
                        TAG: "PreviousAndNextAndContinueLater",
                        next: navigationNextControl,
                        previous: navigationPreviousControl,
                        continueLater: undefined
                      },
                      mobile: false
                    })
              ],
              className: css.container
            });
}

var make = CreateCloudProjectCloudTypeSelection;

export {
  css ,
  CloudType ,
  make ,
}
/* css Not a pure module */
