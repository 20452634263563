// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Project from "../../models/Project.res.js";

function detail(projectId) {
  return "/projects/" + projectId + "/detail";
}

function show(projectId) {
  return "/projects/" + projectId;
}

function success(projectId) {
  return "/projects/" + projectId + "/detail?newProject=true";
}

function update(projectId) {
  return "/projects/" + (projectId + "/general_info");
}

function show$1(projectId) {
  return "/projects/" + (projectId + "/bare-metal-rfp");
}

var Configuration = {
  show: show$1
};

function show$2(projectId) {
  return "/projects/" + (projectId + "/general_info");
}

var GeneralInfo = {
  $$new: "/projects/general_info/bare_metal_rfp",
  show: show$2
};

function editSelector(projectId) {
  return "/projects/" + (projectId + "/select_providers/bare_metal_rfp");
}

var Provider = {
  newSelector: "/projects/select_providers/bare_metal_rfp",
  editSelector: editSelector
};

var BareMetal = {
  $$new: "/projects/bare-metal-rfp",
  Configuration: Configuration,
  GeneralInfo: GeneralInfo,
  Provider: Provider
};

function $$new(cloudType) {
  return "/projects/cloud-rfp/" + Project.Cloud.configurationSlug(cloudType);
}

function show$3(projectId, cloudType) {
  return "/projects/" + (projectId + ("/cloud-rfp/" + Project.Cloud.configurationSlug(cloudType)));
}

var Configuration$1 = {
  $$new: $$new,
  show: show$3
};

function $$new$1(cloudType) {
  return "/projects/general_info/" + Project.Cloud.slug(cloudType);
}

function show$4(projectId) {
  return "/projects/" + (projectId + "/general_info");
}

var GeneralInfo$1 = {
  $$new: $$new$1,
  show: show$4
};

function newSelector(cloudType) {
  return "/projects/select_providers/" + Project.Cloud.slug(cloudType);
}

function editSelector$1(projectId, cloudType) {
  return "/projects/" + (projectId + ("/select_providers/" + Project.Cloud.slug(cloudType)));
}

var Provider$1 = {
  newSelector: newSelector,
  editSelector: editSelector$1
};

var Cloud = {
  $$new: "/projects/cloud-rfp",
  Configuration: Configuration$1,
  GeneralInfo: GeneralInfo$1,
  Provider: Provider$1
};

function update$1(projectId) {
  return "/projects/" + (projectId + "/colocation-rfp");
}

var Configuration$2 = {
  update: update$1
};

function update$2(projectId) {
  return "/projects/" + (projectId + "/select_locations");
}

var $$Location = {
  select: "/projects/select_locations",
  update: update$2
};

var Colocation = {
  $$new: "/projects/colocation-rfp",
  Configuration: Configuration$2,
  $$Location: $$Location
};

var Dashboard = {
  index: "/dashboard/projects",
  conciergeIndex: "/dashboard/concierge/projects"
};

function download(projectId, documentId) {
  return "/api/v1/projects/" + (projectId + ("/download_document/" + Project.DocumentId.toString(documentId)));
}

var $$Document = {
  download: download
};

function $$new$2(category) {
  return "/projects/general_info/" + Project.Category.toString(category);
}

function update$3(projectId) {
  return "/projects/" + (projectId + "/general_info");
}

var GeneralInfo$2 = {
  $$new: $$new$2,
  update: update$3
};

var IaasServer = {
  $$new: "/projects/cloud-rfp/cloud-server-pricing"
};

var IaasStorage = {
  $$new: "/projects/cloud-rfp/cloud-storage-pricing"
};

function show$5(projectId) {
  return "/projects/" + (projectId + "/business-internet-pricing/");
}

var Configuration$3 = {
  show: show$5
};

function download$1(projectId, documentId) {
  return "/api/v1/projects/" + (projectId + ("/download_internet_document/" + Project.DocumentId.toString(documentId)));
}

var $$Document$1 = {
  download: download$1
};

function show$6(projectId) {
  return "/projects/" + (projectId + "/general_info");
}

var GeneralInfo$3 = {
  $$new: "/projects/general_info/internet_rfp",
  show: show$6
};

function editSelector$2(projectId) {
  return "/projects/" + (projectId + "/select_providers/internet_rfp");
}

var Provider$2 = {
  newSelector: "/projects/select_providers/internet_rfp",
  editSelector: editSelector$2
};

var Internet = {
  $$new: "/projects/business-internet-pricing",
  Configuration: Configuration$3,
  $$Document: $$Document$1,
  GeneralInfo: GeneralInfo$3,
  Provider: Provider$2
};

function $$new$3(networkType) {
  return "/projects/network-rfp/" + Project.Network.configurationSlug(networkType);
}

function show$7(projectId, networkType) {
  return "/projects/" + (projectId + ("/network-rfp/" + Project.Network.configurationSlug(networkType)));
}

var Configuration$4 = {
  $$new: $$new$3,
  show: show$7
};

function download$2(projectId, documentId) {
  return "/api/v1/projects/" + (projectId + ("/download_network_document/" + Project.DocumentId.toString(documentId)));
}

var $$Document$2 = {
  download: download$2
};

function $$new$4(networkType) {
  return "/projects/general_info/" + Project.Network.slug(networkType);
}

function show$8(projectId) {
  return "/projects/" + (projectId + "/general_info");
}

var GeneralInfo$4 = {
  $$new: $$new$4,
  show: show$8
};

var Mpls = {
  $$new: "/projects/network-rfp/mpls-pricing"
};

function newSelector$1(networkType) {
  return "/projects/select_network_providers/" + Project.Network.slug(networkType);
}

function editSelector$3(projectId, networkType) {
  return "/projects/" + (projectId + ("/select_network_providers/" + Project.Network.slug(networkType)));
}

var Provider$3 = {
  newSelector: newSelector$1,
  editSelector: editSelector$3
};

var PrivateLine = {
  $$new: "/projects/network-rfp/private-line-pricing"
};

var SdWan = {
  $$new: "/projects/network-rfp/sd-wan-pricing"
};

var Network = {
  $$new: "/projects/network-rfp",
  Configuration: Configuration$4,
  $$Document: $$Document$2,
  GeneralInfo: GeneralInfo$4,
  Mpls: Mpls,
  Provider: Provider$3,
  PrivateLine: PrivateLine,
  SdWan: SdWan
};

function $$new$5(productId) {
  return "/projects/colocation-rfp?productId=" + ID.toString(productId);
}

var Colocation$1 = {
  $$new: $$new$5
};

var Product = {
  Colocation: Colocation$1
};

function download$3(projectId, proposalId) {
  return "/api/v1/projects/" + (projectId + ("/proposals/" + (proposalId + "/download")));
}

function downloadHistory(projectId, proposalId, documentId) {
  return "/api/v1/projects/" + (projectId + ("/proposals/" + (proposalId + ("/download_history/" + Project.DocumentId.toString(documentId)))));
}

function downloadAdditional(projectId, proposalId, documentId) {
  return "/api/v1/projects/" + (projectId + ("/proposals/" + (proposalId + ("/download_additional/" + Project.DocumentId.toString(documentId)))));
}

function downloadAll(projectId, proposalId) {
  return "/api/v1/projects/" + (projectId + ("/proposals/" + (proposalId + "/download_all")));
}

function downloadAllAdditional(projectId, proposalId) {
  return "/api/v1/projects/" + (projectId + ("/proposals/" + (proposalId + "/download_additional_and_proposal")));
}

function index(projectId) {
  return "/projects/" + (projectId + "/proposals");
}

function $$new$6(projectId) {
  return "/projects/" + (projectId + "/proposals/new");
}

function show$9(projectId, proposalId) {
  return "/projects/" + (projectId + ("/proposals/" + proposalId));
}

function update$4(projectId, proposalId) {
  return "/projects/" + (projectId + ("/proposals/" + (proposalId + "/edit")));
}

var Proposal = {
  download: download$3,
  downloadHistory: downloadHistory,
  downloadAdditional: downloadAdditional,
  downloadAll: downloadAll,
  downloadAllAdditional: downloadAllAdditional,
  index: index,
  $$new: $$new$6,
  show: show$9,
  update: update$4
};

function index$1(projectId) {
  return "/projects/" + projectId + "/providers_projects";
}

var Provider$4 = {
  index: index$1
};

var index$2 = "/projects";

var toCsv = "/projects.csv";

export {
  detail ,
  show ,
  success ,
  index$2 as index,
  update ,
  toCsv ,
  BareMetal ,
  Cloud ,
  Colocation ,
  Dashboard ,
  $$Document ,
  GeneralInfo$2 as GeneralInfo,
  IaasServer ,
  IaasStorage ,
  Internet ,
  Network ,
  Product ,
  Proposal ,
  Provider$4 as Provider,
}
/* ID Not a pure module */
