// Generated by ReScript, PLEASE EDIT WITH CARE


var about = "/about-us";

var contact = "/contact";

var contactThankYou = "contact_us/thank_you";

var controlPlaneSignUp = "https://signup.controlplane.com/";

var privacyPolicy = "/privacy-policy";

var sitemap = "/sitemap";

var termsOfUse = "/terms-of-use";

var thanks = "/thanks";

var thanksProvider = "/thanks-provider";

export {
  about ,
  contact ,
  contactThankYou ,
  controlPlaneSignUp ,
  privacyPolicy ,
  sitemap ,
  termsOfUse ,
  thanks ,
  thanksProvider ,
}
/* No side effect */
