// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../../../styleguide/components/Control/Control.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardNavigationScss from "./ProjectWizardNavigation.scss";

var css = ProjectWizardNavigationScss;

function ProjectWizardNavigation$ErrorMessage(props) {
  var errorMessage = props.errorMessage;
  if (errorMessage !== undefined) {
    return JsxRuntime.jsx("div", {
                children: errorMessage,
                className: css.errorMessage
              });
  } else {
    return null;
  }
}

var ErrorMessage = {
  make: ProjectWizardNavigation$ErrorMessage
};

function ProjectWizardNavigation(props) {
  var convertButtonClass = props.convertButtonClass;
  var mobile = props.mobile;
  var controls = props.controls;
  var errorMessage = props.errorMessage;
  var stepLabel = props.stepLabel;
  var convertClass = convertButtonClass !== undefined ? convertButtonClass : "";
  var tmp;
  switch (controls.TAG) {
    case "PreviousOnly" :
        var buttonState = controls._2;
        var handle = controls._1;
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Button.make, {
                      size: mobile ? "SM" : "MD",
                      color: "Gray",
                      visuallyDisabled: buttonState === "Disabled",
                      busy: buttonState === "Busy",
                      onClick: (function (param) {
                          handle();
                        }),
                      children: controls._0
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation$ErrorMessage, {
                      errorMessage: errorMessage
                    })
              ]
            });
        break;
    case "NextOnly" :
        var buttonState$1 = controls._2;
        var handle$1 = controls._1;
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ProjectWizardNavigation$ErrorMessage, {
                      errorMessage: errorMessage
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("span", {
                              children: stepLabel,
                              className: css.stepLabel
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: mobile ? "SM" : "MD",
                              color: "Primary",
                              visuallyDisabled: buttonState$1 === "Disabled",
                              busy: buttonState$1 === "Busy",
                              className: css.nextButton,
                              buttonClassName: convertClass,
                              onClick: (function (param) {
                                  handle$1();
                                }),
                              children: controls._0
                            })
                      ],
                      className: css.nextButtonContainer
                    })
              ]
            });
        break;
    case "PreviousAndNextAndContinueLater" :
        var match = controls.continueLater;
        var match$1 = controls.previous;
        var previousButtonState = match$1[2];
        var handlePrevious = match$1[1];
        var previousLabel = match$1[0];
        var match$2 = controls.next;
        var nextButtonState = match$2[2];
        var handleNext = match$2[1];
        var nextLabel = match$2[0];
        if (match !== undefined) {
          var handleContinueLater = match[1];
          tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Button.make, {
                        size: mobile ? "SM" : "MD",
                        color: "Gray",
                        visuallyDisabled: previousButtonState === "Disabled",
                        busy: previousButtonState === "Busy",
                        onClick: (function (param) {
                            handlePrevious();
                          }),
                        children: previousLabel
                      }),
                  JsxRuntime.jsx(ProjectWizardNavigation$ErrorMessage, {
                        errorMessage: errorMessage
                      }),
                  JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("span", {
                                        children: stepLabel,
                                        className: css.stepLabel
                                      }),
                                  JsxRuntime.jsx(Control.AsLink.make, {
                                        className: css.saveAndContinueButton,
                                        onClick: (function (param) {
                                            handleContinueLater();
                                          }),
                                        children: match[0]
                                      })
                                ],
                                className: css.saveAndContinueContainer
                              }),
                          JsxRuntime.jsx(Button.make, {
                                size: mobile ? "SM" : "MD",
                                color: "Primary",
                                visuallyDisabled: nextButtonState === "Disabled",
                                busy: nextButtonState === "Busy",
                                className: css.nextButton,
                                buttonClassName: convertClass,
                                onClick: (function (param) {
                                    handleNext();
                                  }),
                                children: nextLabel
                              })
                        ],
                        className: css.nextButtonContainer
                      })
                ]
              });
        } else {
          tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Button.make, {
                        size: mobile ? "SM" : "MD",
                        color: "Gray",
                        visuallyDisabled: previousButtonState === "Disabled",
                        busy: previousButtonState === "Busy",
                        onClick: (function (param) {
                            handlePrevious();
                          }),
                        children: previousLabel
                      }),
                  JsxRuntime.jsx(ProjectWizardNavigation$ErrorMessage, {
                        errorMessage: errorMessage
                      }),
                  JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("span", {
                                      children: stepLabel,
                                      className: css.stepLabel
                                    }),
                                className: css.saveAndContinueContainer
                              }),
                          JsxRuntime.jsx(Button.make, {
                                size: mobile ? "SM" : "MD",
                                color: "Primary",
                                visuallyDisabled: nextButtonState === "Disabled",
                                busy: nextButtonState === "Busy",
                                className: css.nextButton,
                                buttonClassName: convertClass,
                                onClick: (function (param) {
                                    handleNext();
                                  }),
                                children: nextLabel
                              })
                        ],
                        className: css.nextButtonContainer
                      })
                ]
              });
        }
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: tmp,
                          className: css.stepButtonsContainer
                        }),
                    className: css.contentWrapper
                  }),
              className: css.steps
            });
}

var make = ProjectWizardNavigation;

export {
  css ,
  ErrorMessage ,
  make ,
}
/* css Not a pure module */
