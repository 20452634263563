// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function fromJs(js) {
  return {
          id: Belt_Option.map(Caml_option.nullable_to_opt(js.id), (function (prim) {
                  return prim;
                })),
          fullName: js.fullName,
          jobTitle: Caml_option.nullable_to_opt(js.jobTitle),
          companyName: js.companyName,
          image: Caml_option.nullable_to_opt(js.image),
          email: js.email,
          phone: js.phone,
          isPrimary: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(js.isPrimary), false),
          isManager: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(js.isManager), false)
        };
}

function toJs(user) {
  return {
          id: Js_null_undefined.fromOption(Belt_Option.map(user.id, ID.toJson)),
          fullName: user.fullName,
          jobTitle: Js_null_undefined.fromOption(user.jobTitle),
          companyName: user.companyName,
          image: Js_null_undefined.fromOption(user.image),
          email: user.email,
          phone: user.phone,
          isPrimary: user.isPrimary,
          isManager: user.isManager
        };
}

function toJson(user) {
  return Json_Encode$JsonCombinators.object([
              [
                "id",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(Belt_Option.map(user.id, ID.toString))
              ],
              [
                "fullName",
                user.fullName
              ],
              [
                "jobTitle",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(user.jobTitle)
              ],
              [
                "companyName",
                user.companyName
              ],
              [
                "email",
                user.email
              ],
              [
                "phone",
                user.phone
              ],
              [
                "isPrimary",
                user.isPrimary
              ],
              [
                "isManager",
                user.isManager
              ]
            ]);
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", Json_Decode$JsonCombinators.option(ID.decoder)),
              fullName: field.required("fullName", Json_Decode$JsonCombinators.string),
              jobTitle: field.required("jobTitle", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              companyName: field.required("companyName", Json_Decode$JsonCombinators.string),
              image: field.required("image", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              phone: field.required("phone", Json_Decode$JsonCombinators.string),
              isPrimary: field.required("isPrimary", Json_Decode$JsonCombinators.bool),
              isManager: field.required("isManager", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function designationText(jobTitle, companyName) {
  return (
          jobTitle !== undefined ? jobTitle + " at " : ""
        ) + companyName;
}

function createEmpty() {
  return {
          id: undefined,
          fullName: "",
          jobTitle: undefined,
          companyName: "",
          image: undefined,
          email: "",
          phone: "",
          isPrimary: false,
          isManager: false
        };
}

export {
  fromJs ,
  toJs ,
  toJson ,
  decoder ,
  fromJson ,
  designationText ,
  createEmpty ,
}
/* decoder Not a pure module */
